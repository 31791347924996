<template>
	<div class="theme-dashboard-view">
		<h1>{{ $t("help_page_header") }}</h1>

		<div class="my-3">
			<h4>{{ $t("help_page_subheading") }}</h4>
			<div
				class="my-3"
				v-for="(qa, index) in questionAnswers"
				:key="index"
				@click="toggleAnswer(index)"
			>
				<div
					class="bg-light p-2 rounded d-flex justify-content-between qa-hover"
				>
					<strong>{{ qa.question }}</strong>
					<span v-show="openAnswers.includes(index)">
						<i class="fa fa-chevron-down" aria-hidden="true"></i>
					</span>
					<span v-show="!openAnswers.includes(index)">
						<i class="fa fa-chevron-right" aria-hidden="true"></i>
					</span>
				</div>
				<div
					class="border p-3"
					style="min-height: 90px"
					v-show="openAnswers.includes(index)"
				>
					{{ qa.answer }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Help",
	data() {
		return {
			questionAnswers: [
				{
					question: this.$t("create_product_question"),
					answer: this.$t("create_product_help"),
				},
				{
					question: this.$t("create_category_question"),
					answer: this.$t("create_category_help"),
				},
				{
					question: this.$t("create_product_bulk_question"),
					answer: this.$t("create_category_bulk_help"),
				},
				{
					question: this.$t("create_category_bulk_question"),
					answer: this.$t("create_category_bulk_help"),
				},
				{
					question: this.$t("assign_order_to_driver_question"),
					answer: this.$t("assign_order_to_driver_help"),
				},
				{
					question: this.$t("track_order_question"),
					answer: this.$t("track_order_help"),
				},
				{
					question: this.$t("call_us"),
					answer: this.$t("call_us_help"),
				},
			],
			openAnswers: [],
		};
	},
	methods: {
		toggleAnswer(index) {
			if (this.openAnswers.includes(index)) {
				this.openAnswers = this.openAnswers.filter((answer) => answer != index);
			} else {
				this.openAnswers.push(index);
			}
		},
	},
};
</script>

<style scoped>
.qa-hover {
	cursor: pointer;
}
.qa-hover:hover {
	background: #aaa !important;
}
</style>
